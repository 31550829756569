/* 
    短信错误码 涉及接口
    1,短信模板列表查询
    2,短信模板报备
    3,自定义短信发送
    4,短信模板删除
    5,短信模板查询
*/
/* 发短信相关接口 */
export const msgApiList = ["/back/send/message/smsRecordPageList", "/back/send/message/phonePool/pageList", "/back/send/message/sendSms", "/back/send/message/smsCancelTiming", "/back/send/message/templateReport", "/back/send/message/templateDelete", "/back/send/message/templatePageList", "/back/send/message/templateList", "/back/send/message/signReport", "/back/send/message/signDelete", "/back/send/message/signPageList", "/back/send/message/signList", "/back/send/message/getNumByMsgId"]
export const msgErrorCode = {
    4000: "服务欠费",
    4001: "用户名错误",
    4002: "密码不能为空",
    4003: "	短信内容不能为空",
    4004: "手机号码错误",
    4006: "IP鉴权错误",
    4007: "用户禁用",
    4008: "tKey错误",
    4009: "密码错误",
    4010: "短信内容超过1000个字符",
    4011: "请求错误",
    4013: "定时时间错误",
    4014: "模板错误",
    4015: "扩展号错误",
    4019: "用户类型错误",
    4026: "手机号码数最大2000个",
    4029: "请使用post请求",
    4030: "Content-Type请使用application/json",
    4031: "模板名称不能为空",
    4032: "模板类型不正确",
    4034: "模板内容不能为空",
    4035: "模板名称已经存在",
    4036: "添加模板信息失败",
    4037: "模板名称最大20字符",
    4038: "模板内容超过最大字符数",
    4040: "模板内容缺少变量值或规则错误",
    4041: "模板内容中变量规范错误",
    4042: "模板变量个数超限",
    4044: "接口24小时限制提交次数超限",
    4045: "查询模板状态失败",
    4046: "查询模板状态1秒1次",
    4047: "模板不存在",
    4063: "模板Id不正确",
    9998: "JSON解析错误",
    9999: "非法请求",
}
export const dealMsgErrorData = (msg) => {
    try{
        const errorCode = Number(msg.split("--")[1])
        const firstErrMsg = msg.split("--")[0]
        if(errorCode){
            const errMsg = msgErrorCode[Number(errorCode)]
            return `${firstErrMsg}，失败原因：${errMsg}！`
        }else{
            return msg 
        }
        
    }catch(err){
        return msg 
    }
}